<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";
import VueMonthlyPicker from "vue-monthly-picker";
import moment from "moment";

export default {
  locales: {
    pt: {
      Graduation: "Graduação",
      Points: "Pontos",
      Premiation: "Premiação",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      Graduation: "Patrocinio",
      Points: "Puntos",
      Premiation: "Premiación",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    VclList,
    Stat,
    VueMonthlyPicker,
  },
  data() {
    return {
      calendar: "",
      monthly: {
        placeHolder: moment().format("MMM/YYYY"),
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      date: moment().format("YYYY-MM"),

      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,

      graduation: {
        previous: "-",
        points: "0",
        next: "-",
        percent: 0,
      },
    };
  },
  methods: {
    getGraduation() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("report/graduation?date=" + this.date)
        .then((response) => {
          if (response.data.status == "success") {
            this.statData = [
              {
                icon: "bx bx-diamond",
                title: "Graduation",
                value: response.data.graduation.name,
              },
              {
                icon: "bx bx-dollar",
                title: "Points",
                value: this.$options.filters.points(
                  response.data.graduation.points
                ),
              },
            ];

            if (response.data.graduation.next) {
              this.graduation.previous = response.data.graduation.name;
              this.graduation.points = response.data.graduation.points;
              this.graduation.next = response.data.graduation.next.name;
              this.graduation.percent =
                ((response.data.graduation.points -
                  response.data.graduation.previous.points) *
                  100) /
                (response.data.graduation.next.points -
                  response.data.graduation.previous.points);
            } else {
              this.graduation.previous = response.data.graduation.name;
              this.graduation.points = response.data.graduation.points;
              this.graduation.next = "-";
              this.graduation.percent = 100;
            }

            this.table.body = response.data.list;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getGraduation();
  },
  watch: {
    calendar: function (v) {
      this.date = moment(v._d).format("YYYY-MM");
      this.getGraduation();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Graduation") }}</h4>
          <div class="d-none page-title-right">
            <vue-monthly-picker
              v-model="calendar"
              dateFormat="MMM/YYYY"
              :placeHolder="monthly.placeHolder"
              :monthLabels="monthly.labels"
              :clearOption="false"
              alignment="center"
            ></vue-monthly-picker>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6">
        <Stat
          :icon="stat.icon"
          :title="stat.title"
          :value="stat.value"
          :image="stat.image"
        />
      </div>
    </div>

    <div v-if="!table.loading" class="card">
      <div class="card-body">
        <b-progress
          style="border-radius: 3px"
          :value="Number(graduation.percent)"
          variant="default"
          height="15px"
        ></b-progress>
        <div class="d-flex justify-content-between">
          <div>
            {{ graduation.previous }}
          </div>
          <div class="text-center">{{ graduation.percent.toFixed(2) }}%</div>
          <div>
            {{ graduation.next }}
          </div>
        </div>
      </div>
    </div>

    <div class="card card-body" v-if="table.errored">
      {{
        t(
          "We apologize, we are unable to retrieve information at this time. Please try again later."
        )
      }}
    </div>
    <div class="card card-body" v-else-if="table.empty">
      {{ t("No records found.") }}
    </div>
    <vcl-list v-if="table.loading" class="col-lg-6 card card-body"></vcl-list>

    <div v-else class="row">
      <div
        v-for="(td, index) in table.body"
        :key="index"
        class="col-xl-3 col-md-6 mt-5"
      >
        <div class="card card-h-fix">
          <div class="card-body p-4 text-center">
            <div class="team-member pin-top d-inline-block rounded-circle">
              <img
                v-if="td.image"
                :src="require('@/assets/images/graduations/' + td.image)"
                alt
              />
              <img
                v-else
                src="@/assets/images/logo-icon.png"
                class="rounded-circle bg-white"
                alt
              />
            </div>
            <div class="media mt-5">
              <div class="media-body">
                <h5 class="mt-4 mb-0 text-dark">{{ td.name }}</h5>
                <h4 class="mb-0 text-dark">
                  {{ td.points | points }}
                </h4>
                <p class="m-0">VML {{ td.vme | points }}</p>
              </div>
            </div>
            <div v-if="td.premiation" class="plan-features">
              {{ td.premiation }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
